.loaderWrap {
    position: fixed;
    top: -60px;
    left: 0;
    width: 100%;
    height: calc(100vh + 120px);
    background-color: rgba(0,0,0,0.9);
    z-index: 20;

    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        position: absolute;
        left: calc(50% - 24px);
        top: calc(50% - 24px);
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}