// TEXT
$textColorPortfolio: #090909;
$textColorHome: #FFFFFF;
$textColorFrame: #FFFFFF;
$textColorBtn: #FFFFFF;

::-webkit-input-placeholder {
    color: #ACACAC;
    font-family: Montserrat;
}
::placeholder {
    color:#ACACAC;
    font-family: Montserrat;
}

%wrapImg{
    width: 32px;
    height: 32px;
    background-size: cover; 
    background-position: center center;
}

%centerElem {
    background-size: cover; 
    background-position: center center;
}
